import { Controller } from 'stimulus';

import cookie from '../lib/cookie';

const COOKIE_NAME = 'cookie-banner-hidden';
const VISIBILITY_STATE = 'is-visible';

export default class extends Controller {
  connect() {
    if (cookie.get(COOKIE_NAME) !== 'true') {
      this.show();
    }
  }

  show() {
    this.element.classList.add(VISIBILITY_STATE);
  }

  hide() {
    cookie.set(COOKIE_NAME, 'true', 365);

    this.element.classList.remove(VISIBILITY_STATE);
  }
}
