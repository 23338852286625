import railsUJS from '@rails/ujs';
import turbolinks from 'turbolinks';
import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';
import 'a11y-toggle';
import googleAnalytics from '../modules/google-analytics';

const application = Application.start();
const context = require.context('../controllers', true, /\.js$/);

application.load(definitionsFromContext(context));
turbolinks.start();
railsUJS.start();

function onTurbolinksLoad() {
  window.a11yToggle();
}

function onDOMContentLoaded() {
  if (window.namnhatt.gaMeasurementId) {
    googleAnalytics.addGtagScriptTag();
    googleAnalytics.enablePageViewTracking();
  }
}

document.addEventListener('turbolinks:load', onTurbolinksLoad, false);
document.addEventListener('DOMContentLoaded', onDOMContentLoaded, false);
