import { Controller } from 'stimulus';
import turbolinks from 'turbolinks';

function reload() {
  turbolinks.visit(window.location, { action: 'replace' });
}

export default class extends Controller {
  connect() {
    this.element.addEventListener('click', reload, false);
  }

  disconnect() {
    this.element.removeEventListener('click', reload, false);
  }
}
