import { Controller } from 'stimulus';
import Awesomplete from 'awesomplete';
import turbolinks from 'turbolinks';
import debounce from 'lodash.debounce';
import { distance } from 'fastest-levenshtein';
import {
  QUICK_SEARCH_SELECT,
  QUICK_SEARCH_FETCH,
  QUICK_SEARCH_FETCH_COMPLETE,
} from '../constants/events';

const FETCH_DEBOUNCE_DELAY = window.namnhatt.env === 'test' ? 1 : 300;
const ON_SELECT_EVENT = new CustomEvent(QUICK_SEARCH_SELECT);
const ON_FETCH_EVENT = new CustomEvent(QUICK_SEARCH_FETCH);
const ON_FETCH_COMPLETE_EVENT = new CustomEvent(QUICK_SEARCH_FETCH_COMPLETE);
const MIN_CHARS = 2;

export default class extends Controller {
  initialize() {
    this.awesomplete = new Awesomplete(this.element, {
      item: (suggestion, input, i) => {
        const name = suggestion.label;
        const gender = suggestion.value.split(',')[1];

        let html = input.trim() === '' ? (
          name
        ) : (
          name.replace(RegExp(Awesomplete.$.regExpEscape(input.trim()), 'gi'), '<mark>$&</mark>')
        );
        html = `<span>${html}</span>`;
        html += ` <span>${gender === 'female' ? 'f' : 'p'}</span>`;

        return Awesomplete.$.create('li', {
          innerHTML: html,
          role: 'option',
          'aria-selected': 'false',
          id: `awesomplete-list-item-${i}`,
        });
      },
      filter: () => true,
      replace: (suggestion) => {
        this.element.value = suggestion.label;
      },
      sort: (a, b) => {
        const lowerNameA = a.label.toLowerCase();
        const lowerNameB = b.label.toLowerCase();
        const lowerInput = this.element.value.toLowerCase();

        return distance(lowerNameA, lowerInput) - distance(lowerNameB, lowerInput);
      },
      autoFirst: true,
      minChars: MIN_CHARS,
    });

    this.onInput = debounce(this.onInput.bind(this), FETCH_DEBOUNCE_DELAY);
    this.onClose = this.onClose.bind(this);

    this.element.addEventListener('input', this.onInput);
    this.element.addEventListener('awesomplete-selectcomplete', this.onSelect);
    this.element.addEventListener('awesomplete-close', this.onClose);
  }

  async onInput() {
    if (this.element.value.length < MIN_CHARS) {
      return false;
    }

    window.dispatchEvent(ON_FETCH_EVENT);

    await fetch(`/snabbsok.json?q=${this.element.value}`)
      .then(response => response.json())
      .then((data) => {
        window.dispatchEvent(ON_FETCH_COMPLETE_EVENT);
        this.awesomplete.list = data.map(n => ({ label: n.name, value: `${n.path},${n.gender}` }));
      });
  }

  onSelect(event) { // eslint-disable-line class-methods-use-this
    window.dispatchEvent(ON_SELECT_EVENT);

    turbolinks.visit(event.text.value.split(',')[0]);
  }

  onClose(event) { // eslint-disable-line class-methods-use-this
    if (event.reason === 'blur') {
      this.element.value = '';
    }
  }
}
