import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['min', 'max']

  connect() {
    this.min = parseInt(this.element.getAttribute('data-min-max-select-min'), 10);
    this.max = parseInt(this.element.getAttribute('data-min-max-select-max'), 10);

    this.onSelectChange = this.onSelectChange.bind(this);

    this.setupEventListeners();
    this.setDisabledOptions();
  }

  setupEventListeners() {
    this.minTarget.addEventListener('change', this.onSelectChange);
    this.maxTarget.addEventListener('change', this.onSelectChange);
  }

  getMinValue() {
    return parseInt(this.minTarget.value, 10);
  }

  getMaxValue() {
    return parseInt(this.maxTarget.value, 10);
  }

  onSelectChange() {
    this.setDisabledOptions();
  }

  setDisabledOptions() {
    Array.from(this.minTarget.children).forEach((option) => {
      option.disabled = parseInt(option.value, 10) > this.getMaxValue(); // eslint-disable-line no-param-reassign, max-len
    });

    Array.from(this.maxTarget.children).forEach((option) => {
      option.disabled = parseInt(option.value, 10) < this.getMinValue(); // eslint-disable-line no-param-reassign, max-len
    });
  }
}
