class GoogleAnalytics {
  constructor() {
    this.gaMeasurementId = window.namnhatt.gaMeasurementId;
  }

  addGtagScriptTag() {
    const script = document.createElement('script');

    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${this.gaMeasurementId}`;

    document.body.appendChild(script);
  }

  enablePageViewTracking() {
    document.addEventListener('turbolinks:load', () => {
      this.trackPageView(`${window.location.pathname}${window.location.search}`);
    });
  }

  trackPageView(path) {
    const data = { page_path: path };

    window.gtag('config', this.gaMeasurementId, data);
  }

  trackEvent(name, params) { // eslint-disable-line class-methods-use-this
    window.gtag('event', name, params);
  }
}

const googleAnalytics = new GoogleAnalytics();

export default googleAnalytics;
