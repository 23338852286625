import { Controller } from 'stimulus';
import BananaChart from '../lib/banana-chart';

export default class extends Controller {
  connect() {
    const name = this.data.get('name');
    const series = JSON.parse(this.data.get('series'));
    const xaxis = JSON.parse(this.data.get('xaxis'));

    this.chart = new BananaChart(this.element, {
      series,
      xaxis,
      tooltip: {
        text: (year, count) => (`${year} fick ${count < 10 ? 'färre än 10' : count} barn tilltalsnamnet ${name}`),
      },
    });
  }

  disconnect() {
    this.chart.destroy();
  }
}
