// The extended Wilkinson's Algorithm
// http://vis.stanford.edu/papers/tick-labels http://vis.stanford.edu/files/2010-TickLabels-InfoVis.pdf
// Original source: https://github.com/quantenschaum/ctplot/blob/master/ctplot/ticks.py

import range from 'lodash.range';

function coverage(dmin, dmax, lmin, lmax) {
  return 1 - (
    0.5 * (((dmax - lmax) ** 2) + ((dmin - lmin) ** 2)) / ((0.1 * (dmax - dmin)) ** 2)
  );
}

function coverageMax(dmin, dmax, span) {
  const drange = dmax - dmin;

  if (span > drange) {
    return 1 - ((0.5 * (span - drange)) ** 2) / ((0.1 * drange) ** 2);
  }

  return 1;
}

function density(k, m, dmin, dmax, lmin, lmax) {
  const r = (k - 1) / (lmax - lmin);
  const rt = (m - 1) / (Math.max(lmax, dmax) - Math.min(lmin, dmin));

  return 2 - Math.max(r / rt, rt / r);
}

function densityMax(k, m) {
  if (k >= m) {
    return 2 - (k - 1) / (m - 1);
  }

  return 1;
}


function simplicity(q, Q, j, lmin, lmax, lstep) {
  const eps = 1e-10;
  const n = Q.length;
  const i = Q.indexOf(q) + 1;
  let v = 0;

  if (
    ((lmin % lstep) < eps)
    || (
      (((lstep - lmin) % lstep) < eps) && (lmin <= 0) && (lmax >= 0)
    )
  ) {
    v = 1;
  } else {
    v = 0;
  }

  return (n - i) / (n - 1) + v - j;
}

function simplicityMax(q, Q, j) {
  const n = Q.length;
  const i = Q.indexOf(q) + 1;
  const v = 1;

  return (n - i) / (n - 1) + v - j;
}

function score(weights, simplicity2, coverage2, density2, legibility) {
  return (
    weights[0]
    * simplicity2
    + weights[1]
    * coverage2
    + weights[2]
    * density2
    + weights[3]
    * legibility
  );
}

function wilkExt(dmin, dmax, m, onlyInside, Q, w) {
  if ((dmin >= dmax) || (m < 1)) {
    return [dmin, dmax, dmax - dmin, 1, 0, 2, 0];
  }

  let bestScore = -1;
  let result;
  let j = 1;

  while (j < Infinity) {
    for (let i = 0; i < Q.length; i++) { // eslint-disable-line no-plusplus
      const q = Q[i];
      const sm = simplicityMax(q, Q, j);

      if (score(w, sm, 1, 1, 1) < bestScore) {
        j = Infinity;
        break;
      }

      let k = 2;

      while (k < Infinity) {
        const dm = densityMax(k, m);

        if (score(w, sm, 1, dm, 1) < bestScore) {
          break;
        }

        const delta = (dmax - dmin) / (k + 1) / j / q;
        let z = Math.ceil(Math.log10(delta));

        while (z < Infinity) {
          const step = j * q * (10 ** z);
          const cm = coverageMax(dmin, dmax, step * (k - 1));

          if (score(w, sm, cm, dm, 1) < bestScore) {
            break;
          }

          const minStart = Math.floor(dmax / step) * j - (k - 1) * j;
          const maxStart = Math.ceil(dmin / step) * j;

          if (minStart > maxStart) {
            z += 1;
            break;
          }

          range(minStart, maxStart + 1).forEach((start) => { // eslint-disable-line no-loop-func
            const lmin = start * (step / j);
            const lmax = lmin + step * (k - 1);
            const lstep = step;

            const s = simplicity(q, Q, j, lmin, lmax, lstep);
            const c = coverage(dmin, dmax, lmin, lmax);
            const d = density(k, m, dmin, dmax, lmin, lmax);
            const scr = score(w, s, c, d, 1);

            if (
              (scr > bestScore)
              && (
                (onlyInside <= 0)
                || (
                  (lmin >= dmin)
                  && (lmax <= dmax)
                )
              )
              && (
                (onlyInside >= 0)
                || (
                  (lmin <= dmin)
                  && (lmax >= dmax)
                )
              )
            ) {
              bestScore = scr;
              result = [lmin, lmax, lstep, j, q, k, scr];
            }
          });

          z += 1;
        }

        k += 1;
      }
    }

    j += 1;
  }

  return result;
}

function getTicks(dmin, dmax, m, onlyInside, Q, w) {
  const arr = wilkExt(dmin, dmax, m, onlyInside, Q, w);
  const lmin = arr[0];
  const lmax = arr[1];
  const lstep = arr[2];

  return range(lmin, lmax + lstep, lstep);
}

const DEFAULT_OPTIONS = {
  onlyInside: -1,
  Q: [1, 5, 2, 2.5, 4, 3],
  w: [0.2, 0.25, 0.5, 0.05],
};
/**
 * Places the ticks according to The extended Wilkinson's Algorithm
 * Options:
 * @param {number} targetDensity - Controls the number of ticks. The algorithm
 *                                 will try to put as many ticks on the axis but
 *                                 deviations are allowed if criterion is
 *                                 considered more important.
 * @param {array} onlyInside - Controls if the first and last label include the
 *                             data range.
 *                             0  : doesn't matter.
 *                             >0 : label range must include data range.
 *                             <0 : data range must be larger than label range.
 * @param {array} Q - Numbers that are considered as 'nice'. Ticks will be
 *                    multiples of these.
 * @param {array} w - Array of weights that control the importance of the four
 *                    criteria: simplicity, coverage, density and legibility.
 */

function getYTicks(vmin, vmax, optionsArg = {}) {
  const options = Object.assign({}, optionsArg, DEFAULT_OPTIONS);

  return getTicks(vmin, vmax, options.targetDensity, options.onlyInside, options.Q, options.w);
}

export default getYTicks;
