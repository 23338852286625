import { SVG } from '@svgdotjs/svg.js';
import getYTicks from './banana-chart/get-y-ticks';

const OPTIONS = {
  paddingLeft: 0,
  paddingRight: 20,
  paddingTop: 10,
  paddingBottom: 5,
  labelWidth: 50,
  labelHeight: 50,
  renderHorizontalLines: true,
  renderVerticalLines: true,
  renderXLabels: true,
  renderYLabels: true,
  xTicksGridLineHeight: 10,
  dotRadius: 8,
};

function distributedCopy(items, n) {
  const elements = [];
  const interval = Math.ceil(items.length / n);

  for (let i = 0; i < n; i++) { // eslint-disable-line no-plusplus
    const item = items[i * interval];

    if (item) {
      elements.push(items[i * interval]);
    }
  }

  return elements;
}

class BananaChart {
  constructor(el, options) {
    this.el = el;
    this.options = options;
    this.series = options.series;
    this.xaxis = options.xaxis;
    this.canvas = SVG().size('100%', '100%').addTo(el);
    this.yTicks = getYTicks(
      0,
      Math.max(...this.series[0].data) || 100,
      { targetDensity: 5 },
    );
    this.dots = [];

    this.onWindowResize = this.onWindowResize.bind(this);
    this.onDotHover = this.onDotHover.bind(this);
    this.onDotMouseout = this.onDotMouseout.bind(this);
    this.tooltip = null;

    this.appendTooltip();
    this.getAndSetSizes();
    this.addEventListeners();
    this.render();
  }

  renderXAxis() {
    const gridVerticalGroup = this.grid.group().addClass('group-grid-vertical');
    const xLabelsWidth = this.gridWidth - OPTIONS.paddingLeft - OPTIONS.paddingRight + OPTIONS.labelWidth; // eslint-disable-line max-len
    const numOfXLabels = Math.floor(xLabelsWidth / OPTIONS.labelWidth);

    const lineShape = [];
    const areaShape = [];
    const dataGroup = this.canvas.group().addClass('group-data');
    const area = dataGroup.group().addClass('group-data-area');
    const line = dataGroup.group().addClass('group-data-line');
    const dots = dataGroup.group().addClass('group-data-dots');
    const xLabelsGroup = this.canvas.group().addClass('group-x-labels');
    const gridHeightWOPadding = this.gridHeight - OPTIONS.paddingTop - OPTIONS.paddingBottom;
    const xLabels = distributedCopy(this.xaxis, numOfXLabels);
    const endY = this.gridHeight - OPTIONS.paddingBottom;

    this.xaxis.forEach((val, i) => {
      const startX = Math.round(((this.gridWidth - OPTIONS.paddingLeft - OPTIONS.paddingRight) / (this.xaxis.length - 1) * i) + OPTIONS.labelWidth + OPTIONS.paddingLeft); // eslint-disable-line max-len
      const startY = this.gridHeight - OPTIONS.paddingBottom;
      const endX = startX;
      const gridLine = this.canvas.line(startX, startY, endX, endY + OPTIONS.xTicksGridLineHeight).addClass('BC_GridLine');
      const shapePointHeight = endY - Math.round(
        gridHeightWOPadding * (
          this.series[0].data[i] / this.yTicks[this.yTicks.length - 1]
        ),
      );
      const dot = this.canvas.circle(OPTIONS.dotRadius).x(startX - OPTIONS.dotRadius / 2).y(shapePointHeight - OPTIONS.dotRadius / 2).addClass('BC_Dot');
      let text;

      lineShape.push([startX, shapePointHeight]);
      areaShape.push([startX, shapePointHeight]);
      gridVerticalGroup.add(gridLine);
      dots.add(dot);
      dot.data('values', { x: val, y: this.series[0].data[i] });
      this.dots.push(dot);

      if (xLabels.includes(val)) {
        text = this.canvas.text(val.toString()).x(startX).addClass('BC_AxisLabel');
        text.x(Math.round(startX - (text.node.getBBox().width / 2))).y(Math.round(this.height - OPTIONS.paddingBottom - text.node.getBBox().height)); // eslint-disable-line max-len
        xLabelsGroup.add(text);
      }
    });

    areaShape.push([
      this.gridWidth + OPTIONS.labelWidth - OPTIONS.paddingRight,
      this.gridHeight - OPTIONS.paddingBottom,
    ]);
    areaShape.push([
      OPTIONS.labelWidth + OPTIONS.paddingLeft,
      this.gridHeight - OPTIONS.paddingBottom,
    ]);
    area.add(this.canvas.polyline(areaShape).addClass('BC_Area'));
    line.add(this.canvas.polyline(lineShape).addClass('BC_Line'));
  }

  getAndSetSizes() {
    this.width = this.el.getBoundingClientRect().width;
    this.height = this.el.getBoundingClientRect().height;
    this.gridWidth = this.width - OPTIONS.labelWidth;
    this.gridHeight = this.height - OPTIONS.labelHeight;
  }

  renderYAxis() {
    const gridHorizontalGroup = this.grid.group().addClass('group-grid-horizontal');
    const yLabelsGroup = this.canvas.group().addClass('group-y-labels');

    this.yTicks.slice().reverse().forEach((val, i) => {
      const startX = OPTIONS.labelWidth + OPTIONS.paddingLeft;
      const startY = Math.round(((this.gridHeight - OPTIONS.paddingTop - OPTIONS.paddingBottom) / (this.yTicks.length - 1) * i) + OPTIONS.paddingTop); // eslint-disable-line max-len
      const endX = this.width - OPTIONS.paddingRight;
      const endY = startY;
      const gridLine = this.canvas.line(startX, startY, endX, endY).addClass('BC_GridLine');
      const text = this.canvas.text(val.toString()).addClass('BC_AxisLabel');

      gridHorizontalGroup.add(gridLine);
      text.x(0).y(Math.round(startY - (text.node.getBBox().height / 2)));
      yLabelsGroup.add(text);
    });
  }

  render() {
    this.grid = this.canvas.group().addClass('group-grid');

    this.renderXAxis();
    this.renderYAxis();
    this.dots.forEach((dot) => {
      dot.mouseover(this.onDotHover.bind(null, dot));
      dot.mouseout(this.onDotMouseout.bind(null, dot));
    });
  }

  onDotHover(dot) {
    const values = dot.data('values');
    const dotBoundingClientRect = dot.node.getBoundingClientRect();
    const elBoundingClientRect = this.el.getBoundingClientRect();
    const tooltipOffsetTop = dotBoundingClientRect.top - elBoundingClientRect.top + OPTIONS.dotRadius; // eslint-disable-line max-len
    let tooltipOffsetLeft = dotBoundingClientRect.left - elBoundingClientRect.left + OPTIONS.dotRadius; // eslint-disable-line max-len
    let tooltipWidth = 0;

    this.tooltip.classList.add('is-open');
    tooltipWidth = this.tooltip.getBoundingClientRect().width;

    if (tooltipOffsetLeft + tooltipWidth > elBoundingClientRect.width) {
      tooltipOffsetLeft -= tooltipWidth + OPTIONS.dotRadius;
    }

    this.tooltip.innerHTML = this.options.tooltip.text(values.x, values.y);
    this.tooltip.style.transform = `translate(${tooltipOffsetLeft}px, ${tooltipOffsetTop}px)`;
  }

  onDotMouseout() {
    this.tooltip.classList.remove('is-open');
  }

  addEventListeners() {
    window.addEventListener('resize', this.onWindowResize);
  }

  appendTooltip() {
    const tooltip = document.createElement('div');
    tooltip.classList.add('BC_Tooltip');
    this.tooltip = tooltip;
    this.el.appendChild(tooltip);
  }

  onWindowResize() {
    this.getAndSetSizes();
    this.dots.forEach((dot) => {
      dot.mouseover(null);
    });
    this.canvas.clear();
    this.render();
  }

  destroy() {
    this.canvas.clear();
    this.el.innerHTML = '';
    window.removeEventListener('resize', this.onWindowResize);
  }
}

export default BananaChart;
