import { Controller } from 'stimulus';
import {
  QUICK_SEARCH_SELECT,
  QUICK_SEARCH_FETCH,
  QUICK_SEARCH_FETCH_COMPLETE,
} from '../constants/events';

const TRIGGER_SELECTOR = '.js-quick-search-modal-trigger';
const IS_OPEN_STATE = 'is-open';
const IS_LOADING_STATE = 'is-loading';
const ESCAPE_KEY_CODE = 27;

export default class extends Controller {
  static targets = ['input'];

  initialize() {
    this.open = this.open.bind(this);
    this.onDocumentClickAndTouch = this.onDocumentClickAndTouch.bind(this);
    this.onDocumentKeyDown = this.onDocumentKeyDown.bind(this);
    this.onFetch = this.onFetch.bind(this);
    this.onFetchComplete = this.onFetchComplete.bind(this);
    this.close = this.close.bind(this);

    this.setupEventListeners();
  }

  disconnect() {
    this.removeEventListeners();
  }

  onDocumentClickAndTouch(event) {
    if (this.isOpen(event.target)) {
      this.close();
    }
  }

  onDocumentKeyDown(event) {
    if (event.keyCode === ESCAPE_KEY_CODE && this.isOpen()) {
      this.close();
    }
  }

  isOpen(element = this.element) {
    return element.classList.contains(IS_OPEN_STATE);
  }

  open() {
    this.element.classList.add(IS_OPEN_STATE);
    this.inputTarget.focus();

    document.addEventListener('click', this.onDocumentClickAndTouch, false);
    document.addEventListener('touchstart', this.onDocumentClickAndTouch, false);
    document.addEventListener('keydown', this.onDocumentKeyDown, false);
  }

  close() {
    this.element.classList.remove(IS_OPEN_STATE);

    document.removeEventListener('click', this.onDocumentClickAndTouch);
    document.removeEventListener('touchstart', this.onDocumentClickAndTouch);
    document.removeEventListener('keydown', this.onDocumentKeyDown, false);
  }

  onFetch() {
    this.element.classList.add(IS_LOADING_STATE);
  }

  onFetchComplete() {
    this.element.classList.remove(IS_LOADING_STATE);
  }

  setupEventListeners() {
    window.addEventListener(QUICK_SEARCH_SELECT, this.close, false);
    window.addEventListener(QUICK_SEARCH_FETCH, this.onFetch, false);
    window.addEventListener(QUICK_SEARCH_FETCH_COMPLETE, this.onFetchComplete, false);

    document.querySelectorAll(TRIGGER_SELECTOR).forEach((el) => {
      el.addEventListener('click', this.open, false);
    });
  }

  removeEventListeners() {
    window.removeEventListener(QUICK_SEARCH_SELECT, this.close, false);
    window.removeEventListener(QUICK_SEARCH_FETCH, this.onFetch, false);
    window.removeEventListener(QUICK_SEARCH_FETCH_COMPLETE, this.onFetchComplete, false);

    document.querySelectorAll(TRIGGER_SELECTOR).forEach((el) => {
      el.removeEventListener('click', this.open, false);
    });
  }
}
